import React, { Suspense, lazy, useEffect } from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import kalyanLogo from "../Images/KM logo@2x.png";
import LoaderGif from "../Gif/loader.gif";

const OnlineMatrimonyPage = lazy(() =>
  import("../Pages/LandingPages/OnlineMatrimonyComponent/OnlineMatrimonyPage")
);
const CampaignLandingPage = lazy(() =>
  import("../Pages/LandingPages/CampaignLandingPage")
);
const MainApp = lazy(() => import("./MainApp"));

function Main() {
  useEffect(() => {
    let loginDevice = window.innerWidth < 1024 ? "Mobile Site" : "Desktop";
    const finalLoginDevice = loginDevice || "Unknown";
    localStorage.setItem("LOGIN_DEVICE", finalLoginDevice);
  }, []);

  const paths = [
    "/online-matrimony-website",
    "/matrimony-for-tamil",
    "/matrimony-for-telugu",
    "/matrimony-for-kannada",
    "/matrimony-for-malayalam",
    "/matrimony-for-kerala",
    "/tamil",
    "/landingpagev3",
    "/kannada",
    "/malayalam",
    "/telugu",
    "/fbregister1",
    "/fbregister2",
    "/fbregister3",
    "/fbregister4",
    "/fbregister5",
    "/fbregister6",
    "/fbregister7",
    "/fbregister8",
    
  ];

  return (
    <BrowserRouter>
      <Suspense
        fallback={
          <div
            style={{
              height: "100vh",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img alt="kalyan" width="200" src="/kln-logo.webp" />
              <img alt="" width="50" src="/Assets/Gif/loader.gif" />
            </div>
          </div>
        }
      >
        <Routes>
          <Route
            path="/campaign/*"
            element={<CampaignLandingPage page="google" />}
          />
          
          {/* Mapping Multiple Paths Correctly */}
          {paths.map((path, index) => (
            <Route key={index} path={path} element={<OnlineMatrimonyPage page="onlinepage" />} />
          ))}
          <Route path="*" element={<MainApp />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}

export default Main;
